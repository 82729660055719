import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { ColorService, desktop, getFontFamily, tablet } from 'services';
import { Icon, IconsNames } from 'components/atoms';
import { Link } from 'react-router-dom';

export interface ICourseCard {
  categoryName: string;
  course: string | React.ReactNode;
  startDate: string;
  discountPrice: string;
  oldPrice: string;
  priceDiff: string;
  img: IconsNames;
  link: string;
  className?: string;
}

export const CourseCard = memo(
  ({
    categoryName,
    course,
    discountPrice,
    oldPrice,
    priceDiff,
    img,
    link,
    className,
  }: ICourseCard) => (
    <Wrapper className={className}>
      <Link to={link}>
        <Unit>{categoryName}</Unit>
        <CourseName>{course}</CourseName>
        <PriceBlock>
          <DiscountPrice>{discountPrice}</DiscountPrice>
          <WrapPrices>
            <OldPrice>{oldPrice}</OldPrice>
            <PricesDifference>{priceDiff}</PricesDifference>
          </WrapPrices>
        </PriceBlock>
        <ImgCourse name={img} />
      </Link>
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  padding: 40px 32px;
  background: ${ColorService.WHITE};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  transition: 0.6s;
  position: relative;
  min-height: 242px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 20px rgba(100, 100, 100, 0.4);
  }

  ${tablet} {
    padding: 40px 32px 40px 40px;
  }

  ${desktop} {
    padding: 40px;
  }
`;

const Unit = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${ColorService.GRAY};
`;

const CourseName = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 18px;
  line-height: 28px;
  color: ${ColorService.MAIN_BLACK};
  margin: 8px 0 44px;
  height: 44px;
  z-index: 1;

  ${tablet} {
    width: 59%;
  }

  ${desktop} {
    width: 100%;
    margin: 8px 0 60px;
  }
`;

const PriceBlock = styled.p`
  margin-top: auto;
  position: relative;
  z-index: 1;
`;

const DiscountPrice = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${ColorService.MAIN_BLACK};
`;

const WrapPrices = styled.div`
  font-size: 14px;
  line-height: 24px;
  display: flex;
  gap: 8px;
`;

const OldPrice = styled.p`
  text-decoration-line: line-through;
  color: ${ColorService.GRAY};
`;

const PricesDifference = styled.p`
  padding: 0 8px;
  background-color: ${ColorService.YELLOW};
  border-radius: 4px;
  width: max-content;
  color: ${ColorService.MAIN_BLACK};
`;

const ImgCourse = styled(Icon)`
  height: 42%;
  width: auto;
  object-fit: contain;
  object-position: right;
  align-self: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;

  ${tablet} {
    height: 40%;
    right: -24px;
  }

  ${desktop} {
    height: 42%;
    right: 0;
  }
`;
