import * as React from 'react';
import { memo } from 'react';

export interface IYoutubeIcon {
  fill?: string;
}

export const YoutubeIcon = memo(({ fill = '#070707' }: IYoutubeIcon) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 3.59136C3 2.85448 3.76989 2.37069 4.43382 2.69036L17.1975 8.83583C17.9401 9.19336 17.9556 10.2452 17.224 10.6246L4.46032 17.2428C3.79466 17.588 3 17.1049 3 16.355V3.59136Z"
      fill={fill}
    />
  </svg>
));
