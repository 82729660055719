import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, getFontFamily, tablet } from 'services';

export const CourseStatistics = memo(() => (
  <Wrapper>
    <LargeBlock>
      <NumberStyled>93%</NumberStyled>
      <Text>
        Трудоустроенных выпускников после консультации в карьерном центре
      </Text>
    </LargeBlock>
    <SmallBlocksWrapper>
      <SmallBlock>
        <Number>170+</Number>
        <Text>Компаний партнеров</Text>
      </SmallBlock>
      <SmallBlock>
        <Number>14 000+</Number>
        <Text>Компаний ищут специалистов в ИТ сфере на рынке СНГ</Text>
      </SmallBlock>
    </SmallBlocksWrapper>
  </Wrapper>
));

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${tablet} {
    flex-direction: row;
  }
`;

const LargeBlock = styled.div`
  background-color: ${ColorService.WHITE};
  border-radius: 24px;
  padding: 32px 32px 94px 32px;

  ${tablet} {
    width: 50%;
    padding: 40px;
  }
`;

const SmallBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${tablet} {
    width: 50%;
  }
`;

const SmallBlock = styled.div`
  background-color: ${ColorService.WHITE};
  border-radius: 24px;
  padding: 32px;

  ${tablet} {
    padding: 40px;
  }
`;

const Number = styled.h3`
  font-family: ${getFontFamily('bold')};
  font-size: 54px;
  line-height: 62px;
  margin-bottom: 4px;
`;

const NumberStyled = styled(Number)`
  margin-bottom: 24px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
`;
