import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, getFontFamily, tablet } from 'services';

export type YouWillLearnType = {
  id: number;
  title: string;
  text: string;
};

interface IYouWillLearnProps {
  youWillLearn: YouWillLearnType[];
}

export const YouWillLearn = memo(({ youWillLearn }: IYouWillLearnProps) => (
  <Wrapper>
    <List>
      {youWillLearn.map(({ id, title, text }) => (
        <Item key={id}>
          <Number>{id}</Number>
          <TextWrap>
            <Bold>{title}</Bold>
            {text}
          </TextWrap>
        </Item>
      ))}
    </List>
  </Wrapper>
));

const Wrapper = styled.div`
  width: 100%;
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-row-gap: 24px;
  justify-content: space-between;

  ${tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 32px;
    grid-column-gap: 24px;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
`;

const Number = styled.p`
  background-color: ${ColorService.MAIN_BLACK};
  border-radius: 999px;
  padding: 0 16px;
  flex-shrink: 0;
  color: ${ColorService.WHITE};
  font-family: ${getFontFamily('bold')};
  max-width: max-content;
`;

const TextWrap = styled.div``;

const Bold = styled.p`
  font-family: ${getFontFamily('bold')};
  margin-bottom: 12px;
`;
