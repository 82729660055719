import React, { memo } from 'react';

import styled from 'styled-components';
import CoverImg from 'assets/images/Cover.png';
import CoverImgTablet from 'assets/images/Cover_tablet.png';
import CoverImgMobile from 'assets/images/Cover_mobile.png';

import { Footer, Header } from 'components/molecules';

import { ColorService, tablet, laptop_small, desktop } from 'services';

export interface ICoverTemplate {
  children: React.ReactElement;
}

export const CoverTemplate = memo(({ children }: ICoverTemplate) => (
  <Paper>
    <Header />

    <ContentWrapper>
      <Wrapper>{children}</Wrapper>
    </ContentWrapper>

    <Footer />
  </Paper>
));

export const Paper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${ColorService.BG_MAIN};
`;

const ContentWrapper = styled.div`
  width: 100%;
  background-image: url(${CoverImgMobile});
  background-repeat: no-repeat;
  background-size: contain;

  ${tablet} {
    background-image: url(${CoverImgTablet});
  }

  ${desktop} {
    background-image: url(${CoverImg});
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh);
  max-width: 1110px;
  display: flex;
  margin: 0 auto 64px auto;
  padding: 112px 16px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${ColorService.TRANSPARENT};

  ${tablet} {
    padding: 152px 40px 0;
    margin-bottom: 80px;
  }

  ${laptop_small} {
    padding: 192px 0 0;
  }
`;
