import React, { memo } from 'react';

import styled from 'styled-components';

import { Icon, IconsNames } from 'components/atoms';
import { getFontFamily, tablet } from 'services';

export const TMSFeatures = memo(() => (
  <Wrapper>
    <Title>Обучение в TeachMeSkills это:</Title>
    <FeaturesList>
      <FeatureItem>
        <StyledIcon name={IconsNames.checkMark} width={24} height={24} />
        <TextWrap>
          <Bold>
            Live-занятия в группах до <br /> 18 человек
          </Bold>
          Никаких лекций в записи, только живое общение на платформе Zoom
        </TextWrap>
      </FeatureItem>
      <FeatureItem>
        <StyledIcon name={IconsNames.checkMark} width={24} height={24} />
        <TextWrap>
          <Bold>Интенсивные тренинги</Bold>
          Никакой воды в обучении, только 15% теории и 85% практики
        </TextWrap>
      </FeatureItem>
      <FeatureItem>
        <StyledIcon name={IconsNames.checkMark} width={24} height={24} />
        <TextWrap>
          <Bold>Сильные преподаватели</Bold>
          Действующие сотрудники международных компаний уровня Kaspersky,
          iTechArt, Exness и др.
        </TextWrap>
      </FeatureItem>
      <FeatureItem>
        <StyledIcon name={IconsNames.checkMark} width={24} height={24} />
        <TextWrap>
          <Bold>Актуальные программы</Bold>
          Разбираем темы по стандартам кадрового рынка образца 2024 года
        </TextWrap>
      </FeatureItem>
      <FeatureItem>
        <StyledIcon name={IconsNames.checkMark} width={24} height={24} />
        <TextWrap>
          <Bold>Этапы наработки портфолио</Bold>
          Уже на курсе наполняем портфолио проектами для быстрого
          трудоустройства
        </TextWrap>
      </FeatureItem>
      <FeatureItem>
        <StyledIcon name={IconsNames.checkMark} width={24} height={24} />
        <TextWrap>
          <Bold>Карьерный центр</Bold>
          Опытные эйчары помогут составить резюме, заполнить профиль в LinkedIn
          и подготовиться к собеседованию
        </TextWrap>
      </FeatureItem>
    </FeaturesList>
  </Wrapper>
));

const Wrapper = styled.div`
  width: 100%;
  margin: 64px 0 0;

  ${tablet} {
    margin: 80px 0 0;
  }
`;

const FeaturesList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-row-gap: 24px;
  justify-content: space-between;

  ${tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 32px;
    grid-column-gap: 24px;
  }
`;

const FeatureItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const TextWrap = styled.div``;

const Bold = styled.p`
  font-family: ${getFontFamily('medium')};
  margin-bottom: 12px;
`;

const Title = styled.div`
  margin-bottom: 24px;
  font-family: ${getFontFamily('bold')};
  font-size: 28px;
  line-height: 32px;

  ${tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;
