import React, { memo } from 'react';

import styled from 'styled-components';

import { Technologies } from 'context';
import { TechnologiesItem } from './TechnologiesItem';

interface ITechnologiesListProps {
  technologies: Technologies[];
}

export const TechnologiesList = memo(
  ({ technologies }: ITechnologiesListProps) => (
    <Wrapper>
      {technologies.map((technology) => (
        <TechnologiesItem key={technology} technology={technology} />
      ))}
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
