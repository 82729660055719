import * as React from 'react';
import { memo } from 'react';

import styled, { css } from 'styled-components';

import { ColorService, getFontFamily } from 'services';

export interface ICategoryTab {
  title: React.ReactNode;
  onClick: () => void;
  isActive: boolean;
}

export const CategoryTab = memo(
  ({ title, onClick, isActive }: ICategoryTab) => (
    <Category isActive={isActive} onClick={onClick}>
      {title}
    </Category>
  ),
);

const Category = styled.button<{ isActive: boolean }>`
  ${({ isActive }) => css`
    font-family: ${getFontFamily('medium')};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    color: ${ColorService.MAIN_BLACK};
    border: none;
    padding: 6px 12px;
    width: max-content;
    cursor: pointer;
    background: ${isActive ? ColorService.YELLOW : ColorService.GRAY_DARK};

    &:hover {
      transition: 0.6s;
      background: ${ColorService.YELLOW_LEMON};
      color: ${ColorService.MAIN_BLACK} !important;
    }

    &:active {
      transition: 0.6s;
      background: ${ColorService.MUSTARD};
      color: ${ColorService.MAIN_BLACK} !important;
    }
  `}
`;
