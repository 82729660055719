import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, getFontFamily, laptop_small } from 'services';

export interface IButton {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  className?: string;
}

export const Button = memo(
  ({ children, onClick, type, className }: IButton) => (
    <ButtonStyled onClick={onClick} type={type} className={className}>
      {children}
    </ButtonStyled>
  ),
);

const ButtonStyled = styled.button`
  width: 100%;
  height: 48px;
  background: ${ColorService.YELLOW};
  border-radius: 8px;
  font-family: ${getFontFamily('medium')};
  font-size: 16px;
  line-height: 20px;
  border: none;
  color: ${ColorService.MAIN_BLACK};
  margin-top: 12px;
  cursor: pointer;

  ${laptop_small} {
    margin: 0;
    width: 237px;
  }

  &:hover {
    transition: 0.6s;
    background: ${ColorService.YELLOW_LEMON};
  }

  &:active {
    transition: 0.6s;
    background: ${ColorService.MUSTARD};
  }
`;
