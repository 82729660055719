import React, { memo } from 'react';

import * as Icons from './icons';

export enum IconsNames {
  close = 'close',
  logo = 'logo',
  phone = 'phone',
  mir = 'mir',
  maestro = 'maestro',
  mastercard = 'mastercard',
  visa = 'visa',
  telegram_yellow = 'telegram_yellow',
  pin = 'pin',
  wallet = 'wallet',
  suitcase = 'suitcase',
  cs_ba = 'cs_ba',
  cs_3d = 'cs_3d',
  cs_ds = 'cs_ds',
  cs_manual_qa = 'cs_manual_qa',
  cs_python = 'cs_python',
  cs_gamedesign = 'cs_gamedesign',
  cs_motion = 'cs_motion',
  cs_cyber = 'cs_cyber',
  cs_react = 'cs_react',
  cs_system = 'cs_system',
  cs_designer = 'cs_designer',
  cs_go = 'cs_go',
  cs_bi = 'cs_bi',
  cs_csharp = 'cs_csharp',
  cs_java = 'cs_java',
  cs_hr = 'cs_hr',
  cs_ios = 'cs_ios',
  cs_android = 'cs_android',
  cs_pdm = 'cs_pdm',
  cs_qa_js = 'cs_qa_js',
  cs_project = 'cs_project',
  cs_qa_python = 'cs_qa_python',
  cs_uxui = 'cs_uxui',
  cs_unity = 'cs_unity',
  cs_qa_java = 'cs_qa_java',
  cs_pa = 'cs_pa',
  cs_unreal_engine = 'cs_unreal_engine',
  cs_qa_csharp = 'cs_qa_csharp',
  cs_web = 'cs_web',
  cs_fe = 'cs_fe',
  cs_devops = 'cs_devops',
  cs_data_engineer = 'cs_data_engineer',
  cs_machine_learning = 'cs_machine_learning',
  cs_one_c = 'cs_one_c',
  star = 'star',
  checkMark = 'checkMark',
  python = 'python',
  java = 'java',
  javaScript = 'javaScript',
  html = 'html',
  css = 'css',
  flask = 'flask',
  gitLab = 'gitLab',
  pyCharm = 'pyCharm',
  cPlusPlus = 'cPlusPlus',
  fastApi = 'fastApi',
  hh = 'hh',
  gift_box = 'gift_box',
  folder = 'folder',
}

export interface IIconProps {
  name: IconsNames;
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
}

const IconsMap: { [key: string]: any } = Icons;

export const Icon = memo(
  ({ name, width = '100%', height = '100%', color, className }: IIconProps) => {
    const IconComponent = IconsMap[name];

    return (
      <IconComponent
        width={width}
        height={height}
        fill={color}
        className={className}
      />
    );
  },
);
