import { useState, useEffect } from 'react';

export const useGeoLocation = () => {
  const [countryCode, setCountryCode] = useState<string>('by');
  const [phoneCode, setPhoneCode] = useState<string>('+375');

  useEffect(() => {
    const setValues = (json: any) => {
      setCountryCode(json?.country_code || 'BY');
      setPhoneCode(json?.country_calling_code || '+375');
    };

    fetch('https://ipapi.co/json')
      .then((data) => data.json())
      .then((json) => {
        setValues(json);
      })
      .catch(() => {
        setCountryCode('BY');
        setPhoneCode('+375');
      });
  }, []);

  return {
    phoneCode,
    countryCode,
    setCountryCode,
    setPhoneCode,
  };
};
