import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, getFontFamily, tablet } from 'services';

interface IErrorForm {
  pressTryAgain: () => void;
  className?: string;
}

export const ErrorForm = memo(({ pressTryAgain, className }: IErrorForm) => (
  <Wrapper className={className}>
    <Content>
      <Title>Упс, произошла ошибка отправки</Title>
      <Subtitle>
        Что-то пошло не так и заявка не была отправлена.{' '}
        <ButtonTryAgain type="button" onClick={() => pressTryAgain()}>
          Попробуйте ещё раз
        </ButtonTryAgain>
      </Subtitle>
    </Content>
  </Wrapper>
));

const Wrapper = styled.div`
  margin: 6px auto;
  width: 100%;
  ${tablet} {
    margin: 8px auto;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 28px;
  line-height: 30px;
  color: ${ColorService.RED};

  ${tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;

const Subtitle = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: ${ColorService.RED};

  ${tablet} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const ButtonTryAgain = styled.button`
  background: none;
  border: none;
  font-family: ${getFontFamily()};
  font-size: 12px;
  line-height: 18px;
  text-decoration: underline;
  color: ${ColorService.RED};
`;
