import React, { memo } from 'react';

import styled from 'styled-components';

import { Technologies, TechnologiesNames } from 'context';
import { Icon, IconsNames } from 'components/atoms';
import { ColorService } from 'services';

interface ITechnologiesItemProps {
  technology: Technologies;
}

export const TechnologiesItem = memo(
  ({ technology }: ITechnologiesItemProps) => (
    <Wrapper>
      <Icon
        name={IconsNames[technology as unknown as IconsNames]}
        width={26}
        height={26}
      />
      {TechnologiesNames[technology]}
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${ColorService.WHITE};
  border-radius: 8px;
  padding: 8px 12px;
`;
