class ColorApiService {
  public TRANSPARENT = 'transparent';

  public WHITE = '#FFFFFF';

  public BLACK = '#070707';

  public MAIN_BLACK = '#2E2E2E';

  public GRAY = '#ACB1B6';

  public GRAY_SHADOW = '#C0C6CD';

  public SILVER = '#575A61';

  public YELLOW = '#FFDA17';

  public MUSTARD = '#F0BE0C';

  public BG_GRAY = '#272B35';

  public BG_GRAY_ADDITIONAL = '#ACB1B6';

  public BG_MAIN = '#F4F5F7';

  public YELLOW_LEMON = '#FFE880';

  public GRAY_LIGHT = '#767A7F';

  public GRAY_DARK = '#E9EAEF';

  public ADDITIONAL_GRAY = '#E7EBEF';

  public RED = '#DE340F';
}

export const ColorService = new ColorApiService();
