import * as React from 'react';
import { memo } from 'react';

export interface ITelegramIcon {
  fill?: string;
}

export const TelegramIcon = memo(({ fill = '#070707' }: ITelegramIcon) => (
  <svg
    width="36"
    height="29"
    viewBox="0 0 36 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9034 19.284L30.4372 28.4896L35.502 0.65625L15.9034 19.284Z"
      fill={fill}
    />
    <path
      d="M12.3793 19.6083L33.0789 0.00585938L0.818298 12.7854L1.14861 12.8937L10.6176 16.3593L13.3702 26.6479L18.3249 23.3989L12.3793 19.6083Z"
      fill={fill}
    />
  </svg>
));
