import { gql, request } from 'graphql-request';
import { useMutation } from 'react-query';

interface ILeadInput {
  phone: string;
  name: string;
  email: string;
  tittle: string;
  formName: string;
  roistatId: string;
  utmTerm?: string;
  utmSource?: string;
  utmMedium?: string;
  utmContent?: string;
  utmCampaign?: string;
}

interface ICreateLead {
  lead: ILeadInput;
}

type ICreateLeadMutation = [
  () => void,
  {
    isLoading: boolean;
    success: boolean;
    error: boolean;
  },
];

const CreateLeadMutation = gql`
  mutation createBFLeadMutation($bfLeadInput: BlackFridayLeadInput!) {
    createBlackFridayLead(bfLeadInput: $bfLeadInput) {
      success
      errors
    }
  }
`;

const endpoint = 'https://erp-api.teachmeskills.ru/graphql';

export const useCreateLead = ({ lead }: ICreateLead): ICreateLeadMutation => {
  const { data, isLoading, mutate, isError } = useMutation(async () =>
    request(endpoint, CreateLeadMutation, { bfLeadInput: lead }),
  );

  const createLeadMutation = () => mutate();

  return [
    createLeadMutation,
    {
      // @ts-ignore
      success: data?.createBlackFridayLead.success,
      // @ts-ignore
      error: Boolean(data?.createBlackFridayLead.errors) || isError,
      isLoading,
    },
  ];
};
