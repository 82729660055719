import React, { memo } from 'react';
import { createPortal } from 'react-dom';

import styled from 'styled-components';

import { ColorService, getFontFamily, tablet } from 'services';
import { Icon, IconsNames, Button } from 'components/atoms';

interface IModalProps {
  closeOnBackdropClick: boolean;
  isShown: boolean;
  hide: () => void;
}

export const SuccessFormModal = memo(
  ({ closeOnBackdropClick, isShown, hide }: IModalProps) => {
    const onOverlayClick = () => closeOnBackdropClick && hide();
    const onJoinClick = () => {
      window.location.replace(
        'https://refer.id/?bot=tms_client_bot&platform=telegram&verbose_name=TeachMeSkills&n=83684&c=4158',
      );
    };
    const modal = (
      <>
        <Backdrop onClick={onOverlayClick} />
        <ModalWrapper>
          <Modal>
            <ModalContent>
              <Header>
                <Title>Спасибо за заявку!</Title>
                <CloseButton onClick={hide}>
                  <Icon name={IconsNames.close} width={24} height={24} />
                </CloseButton>
              </Header>
              <Info>
                Ваша заявка отправлена. Мы свяжемся с вами в течение дня для
                уточнения деталей.
              </Info>

              <TelegramBlock>
                <BtnWrap>
                  <Text>Присоединяйся к нашему телеграм-боту!</Text>
                  <Button onClick={onJoinClick}>Присоединиться</Button>
                </BtnWrap>

                <IconWrapper>
                  <Icon
                    name={IconsNames.telegram_yellow}
                    width={56}
                    height={48}
                  />
                </IconWrapper>
              </TelegramBlock>
            </ModalContent>
          </Modal>
        </ModalWrapper>
      </>
    );

    return isShown ? createPortal(modal, document.body) : null;
  },
);

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${ColorService.BG_GRAY};
  opacity: 0.6;
  z-index: 500;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

const Modal = styled.div`
  z-index: 100;
  background: ${ColorService.WHITE};
  border-radius: 24px;
  position: relative;
  margin: auto;
`;

const ModalContent = styled.div`
  padding: 32px;
  width: 370px;

  ${tablet} {
    padding: 40px;
    width: 636px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.h4`
  font-family: ${getFontFamily('bold')};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;

  ${tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;

const Info = styled.p`
  margin-bottom: 16px;
  line-height: 24px;

  ${tablet} {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 32px;
  }
`;

const TelegramBlock = styled.div`
  background-color: ${ColorService.BG_MAIN};
  border-radius: 16px;
  width: 100%;
  padding: 24px 32px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 130px;

  ${tablet} {
    padding: 32px 40px 40px;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  margin-bottom: 16px;
  line-height: 24px;

  ${tablet} {
    font-size: 20px;
    line-height: 32px;
  }
`;

const IconWrapper = styled.div`
  display: none;

  ${tablet} {
    width: 96px;
    height: 96px;
    border-radius: 24px;
    background-color: ${ColorService.MAIN_BLACK};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
`;
