import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, getFontFamily, tablet } from 'services';
import { Icon, IconsNames } from 'components/atoms/Icon';

export const WhoIsQA = memo(() => (
  <Wrapper>
    <Title>Кто такой Automation QA Engineer?</Title>
    <Text>
      Automation QA (AQA) - это процесс тестирования ПО, позволяющий выполнять и
      анализировать тесты для нахождения уязвимостей и проверки ПО
      автоматически, используя специальные инструменты.
      <br />
      <br />
      QA Automation — золотая середина между тестированием и разработкой.
      <br />
      <br />
      На курсе AQA Engineer ты научишься писать автотесты для web-приложений и
      REST API. Познакомишься с современными тестовыми фреймворками, научишься
      создавать тестовую документацию, анализировать результаты тестов и
      составлять отчеты по ним.
    </Text>
    <HHWrapper>
      <IconStyled name={IconsNames.hh} width={80} height={80} />
      <Text>
        По данным hh.ru, за последний месяц опубликовано{' '}
        <Bold>более 3500 вакансий</Bold> автоматизаторов тестирования.
        <br />А сама профессия подходит как новичкам, так и людям с опытом.
        Например, мануальным тестировщикам.
      </Text>
    </HHWrapper>
  </Wrapper>
));

const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 24px;
  background-color: ${ColorService.WHITE};
  margin-bottom: 64px;

  ${tablet} {
    margin-bottom: 80px;
    padding: 40px;
  }
`;

const Title = styled.h2`
  font-family: ${getFontFamily('bold')};
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 24px;

  ${tablet} {
    font-size: 32px;
    line-height: 38px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 22px;

  ${tablet} {
    font-size: 18px;
    line-height: 26px;
  }
`;

const HHWrapper = styled.div`
  margin-top: 24px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${ColorService.BG_MAIN};
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${tablet} {
    padding: 24px;
    flex-direction: row;
    gap: 24px;
  }
`;

const IconStyled = styled(Icon)`
  flex-shrink: 0;
`;

const Bold = styled.span`
  font-family: ${getFontFamily('medium')};
`;
