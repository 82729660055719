import React, { memo } from 'react';

import styled from 'styled-components';

import { CourseCard, LinkArrow } from 'components/atoms';
import { getFontFamily, tablet } from 'services';
import { AllCourses } from 'context';
import { PATHS } from 'router/config';

export const Courses = memo(() => (
  <Wrapper>
    <Header>
      <Title>Курсы</Title>
      <LinkArrow title="Смотреть все" link={PATHS.courses} />
    </Header>
    <CoursesCards>
      {AllCourses.map(
        ({
          key,
          courseName,
          categoryName,
          startDate,
          discountPrice,
          oldPrice,
          priceDiff,
          img,
          link,
        }) => (
          <StyledCard
            key={key}
            categoryName={categoryName}
            course={courseName}
            startDate={startDate}
            discountPrice={discountPrice}
            oldPrice={oldPrice}
            priceDiff={priceDiff}
            img={img}
            link={link}
          />
        ),
      )}
    </CoursesCards>
  </Wrapper>
));

const Wrapper = styled.div``;

const Title = styled.div`
  font-family: ${getFontFamily('bold')};
  font-size: 28px;
  line-height: 32px;

  ${tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;

const CoursesCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 12px;

  ${tablet} {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
`;

const StyledCard = styled(CourseCard)`
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
`;
