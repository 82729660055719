import React, { memo } from 'react';
import styled from 'styled-components';

import { Footer, Header } from 'components/molecules';

import { ColorService, tablet, laptop_small } from 'services';

export interface IMainTemplate {
  children: React.ReactElement;
}

export const MainTemplate = memo(({ children }: IMainTemplate) => (
  <Paper>
    <Header />

    <ContentWrapper>{children}</ContentWrapper>

    <Footer />
  </Paper>
));

export const Paper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${ColorService.BG_MAIN};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1110px;
  min-height: calc(100vh - 345px);
  display: flex;
  margin: 0 auto 64px auto;
  padding: 112px 16px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${ColorService.TRANSPARENT};

  ${tablet} {
    padding: 152px 40px 0;
    margin-bottom: 80px;
  }

  ${laptop_small} {
    padding: 174px 0 0;
  }
`;
