import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { ColorService } from 'services';

interface ILinkArrow {
  title: string;
  link: string;
}

export const LinkArrow = memo(({ title, link }: ILinkArrow) => (
  <WrapperLink>
    <StyledLink to={link}>{title}</StyledLink>
    <StyledLink to={link}>
      <IconArrow>
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.255063 9.75927C0.0278449 9.51807 0.0391813 9.13834 0.280384 8.91112L4.43216 5.00005L0.280384 1.08898C0.039182 0.861765 0.0278457 0.482035 0.255064 0.240833C0.482282 -0.000369469 0.862012 -0.0117058 1.10321 0.215513L5.71861 4.56332C5.83896 4.67669 5.90719 4.83471 5.90719 5.00005C5.90719 5.1654 5.83896 5.32341 5.71861 5.43679L1.10321 9.78459C0.862011 10.0118 0.482281 10.0005 0.255063 9.75927Z"
            fill="#2e2e2e"
          />
        </svg>
      </IconArrow>
    </StyledLink>
  </WrapperLink>
));

const WrapperLink = styled.div`
  position: relative;
  min-width: 140px;

  a {
    padding-right: 12px;
    color: ${ColorService.MAIN_BLACK};
  }

  &:hover {
    transition: 0.6s;

    a {
      color: #f0be0c !important;
      transition: 0.6s;
    }

    svg path {
      fill: #f0be0c;
      transition: 0.6s;
    }
  }
`;

const StyledLink = styled(Link)`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #070707;
  vertical-align: sub;
  padding-right: 12px;
  position: relative;
  z-index: 1;
`;

const IconArrow = styled.div`
  position: absolute;
  top: 0;
  right: -4px;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;
