import React, { memo } from 'react';

import styled from 'styled-components';

import { tablet, desktop, laptop_small } from 'services/ui';

interface IContainerStyled {
  children: React.ReactNode;
}

export const Container = memo(({ children }: IContainerStyled) => (
  <ContainerStyled>{children}</ContainerStyled>
));

export const ContainerStyled = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  padding: 0 20px;

  ${tablet} {
    padding: 0 40px;
  }

  ${desktop} {
  }

  ${laptop_small} {
    padding: 0;
  }
`;
