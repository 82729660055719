import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ColorService, getFontFamily } from 'services';

interface IBtnGoBack {
  title?: string;
}

export const BtnGoBack = memo(({ title = 'Назад' }: IBtnGoBack) => {
  const navigate = useNavigate();

  return (
    <WrapperLink>
      <StyledLink onClick={() => navigate(-1)}>
        <IconArrow>
          <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.74396 9.75927C5.97118 9.51807 5.95984 9.13834 5.71864 8.91112L1.56686 5.00005L5.71864 1.08898C5.95984 0.861765 5.97118 0.482035 5.74396 0.240833C5.51674 -0.000369523 5.13701 -0.0117058 4.89581 0.215513L0.280417 4.56332C0.160064 4.67669 0.0918322 4.83471 0.0918322 5.00005C0.0918322 5.1654 0.160064 5.32341 0.280417 5.43679L4.89581 9.78459C5.13701 10.0118 5.51674 10.0005 5.74396 9.75927Z"
              fill="#2E2E2E"
            />
          </svg>
        </IconArrow>
        {title}
      </StyledLink>
    </WrapperLink>
  );
});

const WrapperLink = styled.div`
  margin-bottom: 40px;
`;

const StyledLink = styled.button`
  cursor: pointer;
  background: ${ColorService.TRANSPARENT};
  border: 1px solid ${ColorService.GRAY};
  box-sizing: border-box;
  border-radius: 8px;
  font-family: ${getFontFamily()};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${ColorService.MAIN_BLACK};
  vertical-align: sub;
  padding-right: 16px;
  position: relative;
  z-index: 1;
  height: 32px;
  width: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transition: 0.6s;
    background: ${ColorService.YELLOW_LEMON};
    border: 1px solid ${ColorService.YELLOW_LEMON};
  }
`;

const IconArrow = styled.div`
  width: 16px;
`;
